@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  src: url("./fonts/Minecraftia-Regular.ttf") format("truetype");
  font-family: Minecraftia;
  font-display: block;
}
@font-face {
  src: url("./fonts/Minercraftory.ttf") format("truetype");
  font-family: Minercraftory;
  font-display: block;
}
@layer base {
  button {
    @apply border-none bg-transparent inline-block p-0 cursor-pointer;
  }
  canvas,
  img,
  picture,
  svg,
  video {
    @apply block max-w-full select-none;
  }
}
@layer utilities {
  .container {
    @apply w-full ml-auto mr-auto px-6;
  }
  .section {
    @apply mt-20;
  }
  .children-spacing {
    @apply w-full flex flex-col gap-4;
  }
}
:root {
  --clr-lightGray: #acacac;
  --clr-darkGray: #2f2f2f;
  --clr-input: #484d4ace;
}
*,
::after,
::before {
  @apply box-border;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: #00000000;
}
* {
  @apply m-0 p-0;
  line-height: calc(1em + 0.5rem);
}
html {
  @apply scroll-smooth;
}
body {
  @apply bg-black text-white font-minecraftia w-full;
}
main {
  @apply mt-20;
}
.minecraft-button {
  @apply bg-[#c6c6c6] rounded-none py-[0.4rem] text-center w-full;
  border-bottom: 2px solid #646369;
  border-left: 2px solid #f2f2f2;
  border-right: 2px solid #646369;
  border-top: 2px solid #f2f2f2;
}
.input-wrap input,
.pack-type__input,
textarea {
  border: 5px solid var(--clr-darkGray);
}
.minecraft-button p {
  @apply text-[#404040] font-minecraftia font-normal text-input text-xs;
}
.minecraft-button:hover {
  @apply bg-[#208100] cursor-pointer;
  border-bottom: 2px solid #004e00;
  border-left: 2px solid #18be05;
  border-right: 2px solid #004e00;
  border-top: 2px solid #18be05;
}
.minecraft-button:hover p {
  @apply text-white;
}
.minecraft-button:active {
  @apply bg-[#208100];
  border-bottom: 2px solid #18be05;
  border-left: 2px solid #004e00;
  border-right: 2px solid #18be05;
  border-top: 2px solid #004e00;
}
.minecraft-button:active p {
  @apply text-white translate-y-[5px];
}
.required-section {
  @apply mt-16;
}
.input-wrap {
  @apply w-full flex flex-col;
}
.input-wrap p {
  @apply text-sm ml-[5px];
}
.input-wrap input {
  @apply bg-lGray py-[5px] px-[.7rem] text-xs text-input font-minecraftia;
}
.input__pack-type {
  @apply w-full;
}
.pack-type__wrapper {
  @apply relative;
}
.pack-type__heading {
  @apply ml-[5px] text-sm;
}
.pack-type__input {
  @apply flex items-center justify-between py-[.4rem] bg-lGray px-[.7rem];
}
.select__text {
  @apply text-xs text-input pointer-events-none;
}
.pack-type__input img {
  @apply w-[20px] mt-[-5px] pointer-events-none;
}
.pack-type-cover {
  @apply absolute z-10 w-full h-10 top-5 bg-green-400 opacity-30;
}
.pack-type__option-container {
  border: 5px solid #1b1b1b;
  @apply w-full mt-4 absolute top-14;
}
.pack-type__option {
  @apply flex items-center bg-[#2f2f2f] py-[.6rem];
}
.pack-type__option:hover {
  background-color: gray;
}
.pack-type__option .checkbox {
  @apply mx-[1.1rem] w-[10px] h-[10px] relative;
  outline: #222 solid 2.5px;
}
.pack-type__option .checkbox .checked {
  @apply w-full h-full bg-[#c2c2c2ff] absolute top-0 left-0;
  border-bottom: 1.5px solid #8b8b8bff;
  border-left: 1.5px solid #8b8b8bff;
  border-right: 1.5px solid #fff;
  border-top: 1.5px solid #fff;
}
.pack-type__option p {
  @apply text-xs;
}
.advance-mode__link {
  @apply py-[1.2rem] w-full text-[#7f7f7f] flex items-center justify-center flex-col;
}
.advance-mode__link p,
.copied p {
  @apply text-sm;
}
.advance-mode__link img {
  @apply w-[16px];
}
.dropdown-icon-active,
.dropdown-icon-hover {
  @apply hidden;
}
.advance-mode {
  @apply w-full flex-col gap-4;
}
.creator-info {
  @apply mb-[1.8rem];
}
.heading {
  @apply translate-y-[12px] font-normal ml-[5px] text-[0.5rem] font-minecraftory;
}
.hide-icon {
  @apply w-full flex justify-center items-center;
  padding: 1.5rem 0 2.5rem;
}
.hide-icon div img {
  @apply w-[16px] rotate-180;
}
.advance-mode-open,
.mobile-nav-wrapper.open {
  @apply flex;
}
.generate-section {
  @apply mt-4;
}
.missing {
  @apply text-[0.5rem] ml-[1.2rem] mt-2 text-lGray;
}
.manifest-json-txt {
  @apply mt-6 transform-none;
}
textarea {
  @apply min-h-[16rem] py-[5px] px-[.7rem] w-full mb-[1.7rem] text-xs bg-lGray text-input font-minecraftia;
}
textarea::placeholder {
  @apply text-xs text-input font-minecraftia;
}
.copied {
  @apply p-[10px] bg-[#212121] rounded-[3px] w-[87%] top-4 left-[1.4rem] z-[3] fixed hidden;
  outline: black solid 3px;
  border: 5px solid #555;
}
.download-btn {
  @apply mt-[1.7rem] mb-10;
}
.add-dependency-btn {
  @apply h-[2.3rem] flex justify-center items-center py-0 overflow-y-hidden;
}
.add-dependency-btn p {
  @apply inline-block text-4xl font-minecraftory text-input;
}
.mobile-nav-wrapper {
  @apply w-full h-full fixed z-10 bg-[#00000054] hidden;
}
.wrapp {
  @apply absolute w-full h-full translate-x-[-100%];
  transition: transform 0.1s;
}
.mobile-nav {
  @apply bg-[#000] w-60 h-full;
}
.mobile-nav__options {
  @apply flex flex-col;
}
.mobile-nav__options a {
  @apply py-8 px-[2.4rem] no-underline;
}
.mobile-nav__options a:hover {
  @apply bg-[#262626];
}
.mobile-nav__options a:hover p {
  @apply text-white;
}
.mobile-nav__options a p {
  @apply font-minecraftory text-2xl text-[#8e8e8e];
}
@media (min-width: 478px) {
  .container {
    max-width: 478px;
  }
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-inline: 15rem;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-inline: 20rem;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
